<template>
    <div class="section-page">
        <div class="container section-page__container">
            <h1 class="section-page__title section-title section-page__title-mobile" v-html="$t('faq-page.title')" />
            <aside class="section-page__sidebar">
                <div class="section-page__sidebar-inner">
                    <nav class="section-page__nav section-nav section-nav_layout_column">
                        <ul class="section-nav__list">
                            <li class="section-nav__item" v-for="(item, index) of faqData" :key="index">
                                <a :href="`#${item.id}`" class="section-nav__link link" v-smooth-scroll>{{
                                    item.title['ru']
                                }}</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="aside-banner">
                        <img class="aside-banner__img" src="@/assets/images/aside-banner.jpg" alt="" />
                        <router-link
                            :to="{
                                name: 'Airplanes',
                                params: { lang: $i18n.locale },
                            }"
                            class="aside-banner__info"
                        >
                            <span class="aside-banner__title">
                                {{ $t('faq-page.appeal') }}
                                <AppIcon class="aside-banner__icon" name="arrow-right" width="57" height="17" />
                            </span>
                        </router-link>
                    </div>
                </div>
            </aside>
            <div class="section-page__content">
                <h1 class="section-page__title section-title" v-html="$t('faq-page.title')" />
                <article v-for="(item, index) of faqData" :key="index" class="section-page__article" :id="item.id">
                    <h2 class="section-page__subtitle section-subtitle">{{ item.title['ru'] }}</h2>
                    <Accordion>
                        <AccordionItem
                            v-for="(accordion, i) of item.accordionList"
                            :title="accordion.title['ru']"
                            :key="i"
                        >
                            <template v-for="(text, index) of accordion.info">
                                <ul v-if="isArray(text)" :key="index">
                                    <li v-for="(listItem, j) of text" :key="j" v-html="listItem['ru']" />
                                </ul>
                                <p v-else :key="index" v-html="text['ru']" />
                            </template>
                        </AccordionItem>
                    </Accordion>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from '@/components/Accordion'
import AccordionItem from '@/components/AccordionItem'

const faqData = require('./faq.json')

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem,
    },
    data() {
        return {}
    },
    computed: {
        faqData() {
            return faqData
        },
    },
    methods: {
        isArray(arr) {
            return Array.isArray(arr)
        },
    },
}
</script>
