<template>
    <li class="accordion__item">
        <div class="accordion__trigger" :class="{ accordion__trigger_active: visible }" @click="open">
            <h3 class="accordion__title article-title">
                <AppIcon class="accordion__icon" name="star" width="12" height="12" />
                <span v-html="title"></span>
            </h3>
        </div>

        <transition name="accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
            <div class="accordion__content" v-show="visible">
                <div class="accordion__inner content">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </li>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    inject: ['Accordion'],
    data() {
        return {
            index: null,
        }
    },
    computed: {
        visible() {
            return this.index == this.Accordion.active
        },
    },
    created() {
        this.index = this.Accordion.count++
    },
    methods: {
        open() {
            if (this.visible) {
                this.Accordion.active = null
            } else {
                this.Accordion.active = this.index
            }
        },
        start(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        end(el) {
            el.style.height = ''
        },
    },
}
</script>
